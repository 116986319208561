// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.SUP_tickets]: "Заявки в службу поддержки",
  [keys.SUP_id]: "ID",
  [keys.SUP_title]: "Наименование",
  [keys.SUP_number]: "Номер",
  [keys.SUP_status]: "Статус",
  [keys.SUP_addComment]: "Добавить комментарий",
  [keys.SUP_newTicket]: "Создать новую заявку",
  [keys.SUP_contactSupport]: "Обратиться в службу поддержки",
  [keys.SUP_channelSupportHelptext]:
    "Если у вас возникли проблемы с этим номером, вы можете обратиться в нашу службу поддержки.",
  [keys.SUP_topic]: "Тема",
  [keys.SUP_selectTopicError]: "Пожалуйста, выберите тему вашего запроса",
  [keys.SUP_selectWabaError]:
    "Пожалуйста, выберите соответствующую учетную запись",
  [keys.SUP_selectNumber]: "Выберите номер телефона",
  [keys.SUP_selectNumberError]: "Пожалуйста, выберите нужный номер",
  [keys.SUP_selectWabaFirst]: "Сначала выберите бизнес-аккаунт WhatsApp",
  [keys.SUP_messagePlaceholder]: "Чем мы можем вам помочь?",
  [keys.SUP_other]: "Другое",
  [keys.SUP_numberNotAvailable]: "Номер недоступен",
  [keys.SUP_send]: "Отправить сообщение",
  [keys.SUP_back]: "Вернуться к списку",
  [keys.SUP_emptyStateTitle]: "Вы еще не создали ни одной заявки",
  [keys.SUP_emptyStateHelptext]:
    "Откройте заявку в нашей службе поддержки, если у вас возникнут какие-либо вопросы или проблемы. Как только вы откроете заявку, вы сможете увидеть ответы от нашей команды, а также текущий статус вашего запроса.",
  [keys.SUP_success]:
    "Ваше сообщение было отправлено. Наша служба поддержки свяжется с вами.",
  [keys.SUP_successComment]:
    "Ваш комментарий был отправлен. Наша служба поддержки свяжется с вами.",
  [keys.SUP_commentOn]: "Комментарии к заявке",
  [keys.SUP_yourTickets]: "Ваши заявки",
  [keys.SUP_docsLink]: "Документация 360dialog Client Hub",
  [keys.SUP_docsLinkSubtitle]:
    "Узнайте больше о функциях и возможностях 360dialog Client Hub",
  [keys.SUP_shortcutsLink]: "Сочетания клавиш",
  [keys.SUP_shortcutsLinkSubtitle]:
    "Более эффективное управление 360dialog Client Hub",
  [keys.SUP_apiStatusLink]: "Статус WhatsApp Business API",
  [keys.SUP_apiStatusLinkSubtitle]:
    "Официальные обновления статуса API, предоставленные Meta",
  [keys.SUP_supportLink]: "Центр поддержки",
  [keys.SUP_supportLinkSubtitle]:
    "Просматривайте открытые заявки и создавайте новые, чтобы обратиться за помощью или отправить отзыв",
  [keys.SUP_openJiraLink]: "Создать запрос в Jira",
  [keys.SUP_busVerificationIssue]: "Проблема с верификацией бизнеса",
  [keys.SUP_newSubmission]: "Новый запрос",
  [keys.SUP_unableToSubmit]: "Не удалось отправить номер",
  [keys.SUP_requestIVR]: "Запрость номера IVR",
  [keys.SUP_otherQuestion]: "Другой вопрос",
  [keys.SUP_messagingNotWorking]: "Обмен сообщениями не работает",
  [keys.SUP_internalServerErr]: "Внутренняя ошибка сервера",
  [keys.SUP_sandboxSupport]: "Поддержка Sandbox",
  [keys.SUP_hubAccess]: "Доступ к 360dialog Hub",
  [keys.SUP_commercePolicyRejection]:
    "Апелляция при несоответствии нормам коммерческой политики",
  [keys.SUP_click2WhatsApp]: "Включить рекламу Click2WhatsApp",
  [keys.SUP_numberPorting]: "Перенос номера",
  [keys.SUP_nameChange]:
    "Изменение отображаемого имени аккаунта (Display Name)",
  [keys.SUP_nameRejected]: "Отображаемое имя (Display Name) отклонено",
  [keys.SUP_cancellation]: "Отключение (удаление номера)",
  [keys.SUP_onPremise]: "Поддержка On-premise",
  [keys.SUP_paymentMethod]: "Способ оплаты",
  [keys.SUP_govAccount]: "Заявка от правительственной организации",
  [keys.SUP_outsideWorkHours]:
    "Вы отправили новое обращение в службу поддержки в нерабочее время нашего офиса. Наша служба поддержки клиентов работает с понедельника по пятницу с 2:00 до 23:00 UTC+1. Мы свяжемся с вами в ближайшее время.",
  [keys.SUP_openStatusPage]: "Открыть страницу состояния",
  [keys.SUP_allSystemsNormal]: "Все системы в норме",
  [keys.SUP_expIssues]: "Возникли проблемы",
  [keys.SUP_underMaintenance]: "Идут технические работы",
  [keys.SUP_warningCreateTicket1]:
    "Внимание! В настоящее время наша система работает со сбоями. Для получения более подробной информации, пожалуйста, посетите <LINK>https://status.360dialog.com/ | страницу состояния<LINK>. Пожалуйста, воздержитесь от отправки заявок по указанным темам.",
  [keys.SUP_warningCreateTicket2]:
    "Вы можете подписаться на обновления, касающиеся инцидента, с помощью  <LINK>https://status.360dialog.com/ | страницы состояния<LINK>.",
  [keys.SUP_statusLinkSubtitle]:
    "Просматривайте и получайте уведомления по любым текущим или прошлым инцидентам",
  [keys.SUP_messageLengthError]:
    "Сообщение должно содержать не менее 140 символов",
  [keys.SUP_clickHereButtonLabel]: "Нажмите здесь",
  [keys.SUP_paymentMethods]: "Способы оплаты",
  [keys.SUP_attachFile]: "Прикрепить изображения",
  [keys.SUP_includeAllTickets]: "Включая запросы от моих коллег",
  [keys.SUP_reporter]: "Заявитель",
  [keys.SUP_optionsLoading]: "Параметры загружаются…",
  [keys.SUP_roadmap]: "План развития и запросы новых функций",
  [keys.SUP_roadmapSubtitle]:
    "Просмотрите наш план развития, отправляйте запросы на новые функции или поддержите опубликованные",
  [keys.SUP_inReview]: "на проверке",
  //  [keys.SUP_responseViaEmail]: "translation_missing",
  //  [keys.SUP_onboarding]: "translation_missing",
  //  [keys.SUP_onboardingDescription]: "translation_missing",
  //  [keys.SUP_problemWithMessaging]: "translation_missing",
  //  [keys.SUP_messagingProblemsDescription]: "translation_missing",
  //  [keys.SUP_accManagement]: "translation_missing",
  //  [keys.SUP_accManagementDescription]: "translation_missing",
  //  [keys.SUP_offboarding]: "translation_missing",
  //  [keys.SUP_offboardingDescription]: "translation_missing",
  //  [keys.SUP_financeAndBilling]: "translation_missing",
  //  [keys.SUP_financeAndBillingDescription]: "translation_missing",
  //  [keys.SUP_otherQuestion]: "translation_missing",
  //  [keys.SUP_otherQuestionDescription]: "translation_missing",
  //  [keys.SUP_visitStatusPage1]: "translation_missing",
  //  [keys.SUP_visitStatusPage2]: "translation_missing",
};

export default translate;
