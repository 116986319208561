// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  TOUR_eventFunnelTitle: "TOUR_eventFunnelTitle",
  TOUR_eventFunnelDesc: "TOUR_eventFunnelDesc",
  TOUR_addStepsTitle: "TOUR_addStepsTitle",
  TOUR_addStepsDesc: "TOUR_addStepsDesc",
  TOUR_highlightCardsTitle: "TOUR_highlightCardsTitle",
  TOUR_highlightCardsDesc: "TOUR_highlightCardsDesc",
  TOUR_understandSourcesTitle: "TOUR_understandSourcesTitle",
  TOUR_understandSourcesDescTitle: "TOUR_understandSourcesDescTitle",
  TOUR_understandSourcesDescOne: "TOUR_understandSourcesDescOne",
  TOUR_understandSourcesDescTwo: "TOUR_understandSourcesDescTwo",
  TOUR_understandSourcesDescThree: "TOUR_understandSourcesDescThree",
  TOUR_understandSourcesDescFour: "TOUR_understandSourcesDescFour",
  TOUR_dateFiltersTitle: "TOUR_dateFiltersTitle",
  TOUR_dateFiltersDesc: "TOUR_dateFiltersDesc",
};

export default keys;
