// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.PRE_title]: "Preferências",
  [keys.PRE_display]: "Display",
  [keys.PRE_themeTitle]: "Tema",
  [keys.PRE_themeSubtitle]: "Selecione o esquema de cores da sua página",
  [keys.PRE_theme_light]: "Claro",
  [keys.PRE_theme_dark]: "Escuro",
  [keys.PRE_theme_system]: "Preferências do sistema",
  [keys.PRE_behavior]: "Comportamento",
  [keys.PRE_shortcutsTitle]: "Permitir atalhos de teclado",
  [keys.PRE_shortcutsSubtitle]:
    "Digite ? em qualquer lugar ou clique aqui para uma lista de todos os atalhos de teclado",
  [keys.PRE_shortcutsCardTitle]: "Atalhos de teclado",
  [keys.PRE_showAll]: "Mostrar todos os atalhos",
  [keys.PRE_back]: "Voltar",
  [keys.PRE_nextTab]: "Próxima aba",
  [keys.PRE_prevTab]: "Aba anterior",
  [keys.PRE_nextPage]: "Próxima página",
  [keys.PRE_prevPage]: "Página anterior",
  [keys.PRE_focusSearch]: "Focar no campo de busca",
  [keys.PRE_areaGeneral]: "Geral",
  [keys.PRE_areaNav]: "Navegação",
  [keys.PRE_areaNavAdmin]: "Navegação (Admin)",
  [keys.PRE_areaNumbers]: "Lista de números",
  [keys.PRE_areaTemplates]: "Gerenciamento de templates",
  [keys.PRE_areaPreferences]: "Preferências",
  [keys.PRE_integrations]: "Integrações",
  [keys.PRE_partners]: "Parceiros",
  [keys.PRE_showHide]: "Mostrar / ocultar status detalhado",
  [keys.PRE_openTemplateManager]: "Abrir gerenciador de templates",
  [keys.PRE_showDetails]: "Mostrar detalhes",
  [keys.PRE_newTemplate]: "Novo template",
  [keys.PRE_openPreview]: "Abrir prévia",
  [keys.PRE_duplicateTemplate]: "Duplicar",
  [keys.PRE_addLang]: "Adicionar idioma",
  [keys.PRE_themeToggle]: "Trocar tema",
  [keys.PRE_disableShortcuts]: "Desabilitar atalhos",
  [keys.PRE_langTitle]: "Idioma",
  [keys.PRE_langSubtitle]: "Selecionar seu idioma de preferência",
  [keys.PRE_loadingBillingPortal]: "Carregando portal financeiro",
  [keys.PRE_alertNoPayment]:
    "Nenhum método de pagamento configurado. Por favor adicione um cartão de crédito no <LINK>.",
  [keys.PRE_alertNoPaymentLinkLabel]: "Menu de opções de pagamento",
  [keys.PRE_alertNoPaymentCountry]:
    "Para adicionar fundos ao seu saldo de mensagens template, você precisa adicionar a identificação fiscal de sua empresa na página de opções de faturamento.",
  [keys.PRE_alertNoPaymentCountryLinkLabe]:
    "Ir para o menu de opções de faturamento",
  [keys.PRE_copyUrl]: "Copiar URL atual (com URL base hub.360dialog.com)",
  [keys.PRE_otherAdmin]: "Outro (Admin)",
  //  [keys.PRE_Privacy]: "translation_missing",
  //  [keys.PRE_changeCookies]: "translation_missing",
  //  [keys.PRE_changeCookiesDescription]: "translation_missing",
  //  [keys.PRE_cookiesChanged]: "translation_missing",
};

export default translate;
