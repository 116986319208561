// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  SET_partnerName: "SET_partnerName",
  SET_partnerPermissions: "SET_partnerPermissions",
  SET_selectTitle: "SET_selectTitle",
  SET_saveButton: "SET_saveButton",
  SET_successGrantPermissions: "SET_successGrantPermissions",
  SET_Inboxes: "SET_Inboxes",
  SET_selectNumberInbox: "SET_selectNumberInbox",
  SET_requestPartnerChange: "SET_requestPartnerChange",
  SET_requestApproved: "SET_requestApproved",
  SET_requestPending: "SET_requestPending",
  SET_selectNewPartner: "SET_selectNewPartner",
  SET_confirmTerms: "SET_confirmTerms",
  SET_submitPartnerChangeRequest: "SET_submitPartnerChangeRequest",
  SET_addPartnerIdMessage: "SET_addPartnerIdMessage",
  SET_enterPartnerId: "SET_enterPartnerId",
  SET_notValidPartnerId: "SET_notValidPartnerId",
  SET_partnerIdFound: "SET_partnerIdFound",
  SET_newPartnerText: "SET_newPartnerText",
  SET_currentPartnerText: "SET_currentPartnerText",
  SET_currencyMismatchErrorText: "SET_currencyMismatchErrorText",
  SET_messageSupportLabel: "SET_messageSupportLabel",
  SET_messageSupportPlaceholder: "SET_messageSupportPlaceholder",
  SET_billingPlanDesc1: "SET_billingPlanDesc1",
  SET_billingPlanDesc2: "SET_billingPlanDesc2",
  SET_billingPlanDesc3: "SET_billingPlanDesc3",
  SET_billingPlanDesc4: "SET_billingPlanDesc4",
  SET_partnerChangeConditionText: "SET_partnerChangeConditionText",
  SET_messageToNewPartner: "SET_messageToNewPartner",
  SET_messageToNewPartnerPlaceholder: "SET_messageToNewPartnerPlaceholder",
  SET_partnerChangeRequestSubmitted: "SET_partnerChangeRequestSubmitted",
  SET_cancelling: "SET_cancelling",
  SET_rejectionInputLabel: "SET_rejectionInputLabel",
  SET_rejectionInputPlaceholder: "SET_rejectionInputPlaceholder",
  SET_rejectionInputError: "SET_rejectionInputError",
  SET_rejectClient: "SET_rejectClient",
  SET_acceptClient: "SET_acceptClient",
  SET_allExternalWabaMustHaveSameCurrency:
    "SET_allExternalWabaMustHaveSameCurrency",
  SET_paymentPlanCurrencyHasToMatch: "SET_paymentPlanCurrencyHasToMatch",
  SET_partnerChangeRequestTargetPartnerPaymentPlanRequired:
    "SET_partnerChangeRequestTargetPartnerPaymentPlanRequired",
  SET_partnerChangeRequestTargetPartnerPricingPlanRequired:
    "SET_partnerChangeRequestTargetPartnerPricingPlanRequired",
  SET_partnerChangeRequestClientPaymentMethodRequired:
    "SET_partnerChangeRequestClientPaymentMethodRequired",
  SET_wabaAndPaymentPlanCurrencyMustMatch:
    "SET_wabaAndPaymentPlanCurrencyMustMatch",
  SET_paymentPlanHasToMatch: "SET_paymentPlanHasToMatch",
  SET_addPaymentMethod: "SET_addPaymentMethod",
  SET_migrationNote: "SET_migrationNote",
  SET_apiKeyRecommendation: "SET_apiKeyRecommendation",
  SET_languageSelection: "SET_languageSelection",
  SET_askForRefund: "SET_askForRefund",
  SET_disabledPartnerChangeTooltip: "SET_disabledPartnerChangeTooltip",
  SET_addCompanyInfo: "SET_addCompanyInfo",
  SET_selectPlan: "SET_selectPlan",
  SET_triggerRefundMessage: "SET_triggerRefundMessage",
  SET_errorPartnerChange: "SET_errorPartnerChange",
  SET_errorPartnerChangeInfo: "SET_errorPartnerChangeInfo",
  SET_errorPartnerChangeHelp: "SET_errorPartnerChangeHelp",
  SET_PCRNotEnabled: "SET_PCRNotEnabled",
  SET_standardBillNotice: "SET_standardBillNotice",
  SET_premiumBillNotice: "SET_premiumBillNotice",
  SET_updateDetails: "SET_updateDetails",
  SET_generalInfo: "SET_generalInfo",
  SET_nameCompany: "SET_nameCompany",
  SET_locationCompany: "SET_locationCompany",
  SET_editDetails: "SET_editDetails",
  SET_saveDetails: "SET_saveDetails",
  SET_checkYourInvoices: "SET_checkYourInvoices",
  SET_invoiceId: "SET_invoiceId",
  SET_invoiceIssueAt: "SET_invoiceIssueAt",
  SET_inviteMember: "SET_inviteMember",
  SET_inviteMemberProject: "SET_inviteMemberProject",
  SET_member: "SET_member",
  SET_companyMembers: "SET_companyMembers",
  SET_role: "SET_role",
  SET_informationPartner: "SET_informationPartner",
  SET_changePartner: "SET_changePartner",
  SET_currentPartner: "SET_currentPartner",
  SET_currentPartnerInfo: "SET_currentPartnerInfo",
  SET_selectedPaymentMethod: "SET_selectedPaymentMethod",
  SET_changePaymentMethod: "SET_changePaymentMethod",
  SET_cardDetails: "SET_cardDetails",
  SET_cardHolder: "SET_cardHolder",
  SET_cardExpiryDate: "SET_cardExpiryDate",
  SET_cardEndsWith: "SET_cardEndsWith",
  SET_card: "SET_card",
  SET_changeEmail: "SET_changeEmail",
  SET_verifyEmailText: "SET_verifyEmailText",
  SET_emailChangeSuccessful: "SET_emailChangeSuccessful",
  SET_passcodeText: "SET_passcodeText",
  SET_emailToChangeToText: "SET_emailToChangeToText",
  SET_changeName: "SET_changeName",
  SET_nameToChangeToText: "SET_nameToChangeToText",
  SET_nameChangeSuccessful: "SET_nameChangeSuccessful",
  SET_messagingAndPaymentPlanCurrencyMustMatchForINR:
    "SET_messagingAndPaymentPlanCurrencyMustMatchForINR",
  SET_general: "SET_general",
  SET_payment: "SET_payment",
  SET_support: "SET_support",
  SET_partner: "SET_partner",
  SET_adAccounts: "SET_adAccounts",
  SET_invoices: "SET_invoices",
  SET_documentation: "SET_documentation",
  SET_roadmap: "SET_roadmap",
  SET_logout: "SET_logout",
  SET_companyAndBilling: "SET_companyAndBilling",
  SET_teamManagement: "SET_teamManagement",
  SET_syncedAdAccounts: "SET_syncedAdAccounts",
  SET_getAssistance: "SET_getAssistance",
  SET_selectedPayment: "SET_selectedPayment",
  SET_issuedInvoices: "SET_issuedInvoices",
  SET_aboutPartner: "SET_aboutPartner",
  SET_companyGeneral: "SET_companyGeneral",
  SET_connectAdAccount: "SET_connectAdAccount",
  SET_adAccountsDescription: "SET_adAccountsDescription",
  SET_adAccountsEmptyTitle: "SET_adAccountsEmptyTitle",
  SET_adAccountsEmptyDescription: "SET_adAccountsEmptyDescription",
  SET_accountName: "SET_accountName",
  SET_accountID: "SET_accountID",
  SET_refreshData: "SET_refreshData",
  SET_successfulRefreshedDataMsg: "SET_successfulRefreshedDataMsg",
  SET_refreshingDataInFlightMsg: "SET_refreshingDataInFlightMsg",
  SET_noInvoices: "SET_noInvoices",
  SET_noInvoicesDescription: "SET_noInvoicesDescription",
  SET_name: "SET_name",
  SET_email: "SET_email",
  SET_paid: "SET_paid",
  SET_unpaid: "SET_unpaid",
  SET_error: "SET_error",
  SET_pending: "SET_pending",
  SET_processing: "SET_processing",
  SET_refunded: "SET_refunded",
  SET_refundTriggered: "SET_refundTriggered",
  SET_partiallyRefunded: "SET_partiallyRefunded",
  SET_partialRefundTriggered: "SET_partialRefundTriggered",
  SET_triggered: "SET_triggered",
  SET_unknownStatus: "SET_unknownStatus",
  SET_status: "SET_status",
  SET_companyTeamMembers: "SET_companyTeamMembers",
  SET_confirmPaymentMethod: "SET_confirmPaymentMethod",
  SET_confirmPaymentMethodDesc: "SET_confirmPaymentMethodDesc",
  SET_pendingApprovalStatus: "SET_pendingApprovalStatus",
  SET_cancelPCRTitle: "SET_cancelPCRTitle",
  SET_cancelPCRDesc: "SET_cancelPCRDesc",
  SET_submitPartnerChangeRequestMessage:
    "SET_submitPartnerChangeRequestMessage",
  SET_PCRfailed: "SET_PCRfailed",
  SET_funds: "SET_funds",
  SET_aboutFunds: "SET_aboutFunds",
  SET_activity: "SET_activity",
  SET_aboutActivity: "SET_aboutActivity",
};

export default keys;
