import * as profile from "./profile";
import * as wabas from "../waba/waba";
import * as partners from "../partners/partners";
import * as clients from "../clients/clients";
import * as projects from "../projects/projects";
import * as wabaAccounts from "../wabaAccount/wabaAccount";
import * as channels from "../channels/channels";
import * as partnerChannels from "../partnerChannels/partnerChannels";
import * as users from "../user/hubUser";
import * as apiKeys from "../apiKeys/apiKeys";
import * as comments from "../comments/comments";
import * as wabaTemplates from "../wabaTemplates/wabaTemplates";
import * as jiraTicket from "../jiraTicket/jiraTicket";
import * as accounting from "../accounting/accounting";
import * as paymentConnections from "../paymentConnections/paymentConnections";
import * as publicRequests from "../publicRequests/public";
import * as statusPage from "../statusPage/statusPage";
import * as notifications from "../notifications/notifications";
import * as insights from "../insights/insights";
import * as integrations from "../integrations/integrations";
import * as audiences from "../audiences/audiences";
import * as tour from "../tour/tour";
import * as tos from "../tos/tos";
import * as auth from "../auth/authHub";

const admin = {
  auth: {
    get: {
      retrieveOtpInfo: auth.retrieveOtpInfo,
    },
  },
  profile: {
    updateLastManaged: profile.updateLastManaged,
    getUserProfile: profile.getUserProfile,
  },
  apiKeys: {
    get: {
      list: apiKeys.getApiKeys,
    },
    delete: {
      all: apiKeys.deleteApiKeys,
    },
    post: apiKeys.createApiKey,
  },
  wabaTemplates: {
    get: {
      list: wabaTemplates.getWabaTemplates,
    },
    sync: wabaTemplates.syncWabaTemplates,
    post: wabaTemplates.addWabaTemplate,
    delete: {
      single: wabaTemplates.deleteWabaTemplate,
      all: wabaTemplates.deleteAllWabaTemplates,
    },
  },
  wabas: {
    get: {
      list: wabas.getWabas,
      single: channels.getIntegration,
    },
    post: wabas.setupWaba,
    registration: {
      post: {
        trigger: wabas.wabaTriggerRegistration,
        triggerWithAppId: wabas.wabaTriggerRegistrationWithAppId,
        finish: wabas.wabaFinishRegistration,
      },
    },
  },
  partners: {
    get: {
      list: partners.getPartners,
      single: partners.getPartner,
      check: partners.getPartnerPaymentInfo,
    },
    post: partners.addPartner,
    patch: partners.editPartner,
    fbAccountInfo: {
      get: partners.getFacebookBusinessAccountInfo,
    },
    filterOptions: {
      get: partners.getPartnerFilterOptions,
    },
    publicFilterOptions: {
      get: partners.getPublicFilterOptions,
    },
    partnerUser: {
      post: partners.addPartnerUser,
    },
    activations: {
      triggerActivation: partners.triggerActivation,
      approve: partners.approve,
    },
    apiKey: {
      post: partners.addApiKey,
    },
    addLogo: partners.addLogo,
    getPaymentPlans: partners.getPaymentPlans,
  },
  users: {
    checkExistence: users.checkUser,
    get: {
      list: users.getUsers,
      single: users.getUser,
    },
    post: users.addUser,
    delete: users.deleteUser,
  },
  clients: {
    get: {
      list: clients.getClientsList,
      single: clients.getClient,
      csvData: clients.getClientData,
      billingData: clients.getClientBillingData,
      invoices: clients.getClientInvoices,
      clienInvoiceAsPDF: clients.getClientInvoiceAsPDF,
      paymentMethods: clients.getClientPaymentMethods,
      unpaidInvoiceLink: clients.getClientUnpaidInvoiceLink,
      members: clients.getMembers,
    },
    post: clients.createClient,
    setClientVatId: clients.setClientVatId,
    generateClientDataToEmail: clients.generateClientDataToEmail,
    patch: clients.updateClient,
    payment: {
      get: clients.getClientPaymentToken,
      check: clients.checkPayment,
      additionalDetails: clients.submitAdditionalDetails,
      addPaymentMethod: clients.addPaymentMethod,
      premigratePbs: clients.premigratePbs,
    },
    fbCallback: clients.postFbCallbackToken,
    partnerChangeRequest: {
      create: clients.createPartnerChangeRequest,
      get: {
        list: clients.getPartnerChangeRequests,
        preview: clients.getPartnerChangeRequestPreview,
      },
      post: {
        cancel: clients.cancelPartnerChangeRequest,
        finalize: clients.finalizePartnerChangeRequest,
      },
    },
    addUserToClient: clients.addNewUserToExistingClient,
  },
  projects: {
    get: {
      list: projects.getProjectsList,
      single: projects.getProject,
      apiKey: projects.getApiKey,
    },
    post: projects.addProject,
    patch: projects.editProject,
  },
  wabaAccounts: {
    get: {
      list: wabaAccounts.getWabaAccountsList,
      single: wabaAccounts.getWabaAccount,
    },
    post: wabaAccounts.addWabaAccount,
    patch: wabaAccounts.editWabaAccount,
    delete: wabaAccounts.deleteWabaAccount,
    activations: {
      post: {
        submit: wabaAccounts.submitWaba,
        updateTemplateNamespace: wabaAccounts.updateTemplateNamespace,
        synchronizeStatus: wabaAccounts.synchronizeStatus,
        messageOnBehalfReminder: wabaAccounts.messageOnBehalfReminder,
        businessVerificationReminder: wabaAccounts.businessVerificationReminder,
      },
    },
    payment: {
      post: wabaAccounts.paymentWaba,
    },
  },
  synchronizeSharedWaba: {
    post: wabaAccounts.synchronizeSharedWaba,
  },
  paymentConnection: {
    approve: paymentConnections.approveMessageForwarding,
  },
  channels: {
    get: {
      list: channels.getChannelsList,
      single: channels.getChannel,
      profile: channels.getProfile,
      paymentPlans: channels.getPaymentPlans,
    },
    uploadProfilePhoto: channels.uploadProfilePhoto,
    post: channels.addChannel,
    patch: {
      editChannel: channels.editChannel,
      editWaba: channels.editWaba,
      adminEditChannel: channels.adminEditChannel,
    },
    delete: channels.deleteChannel,
    financialReport: {
      get: channels.getFinancialReport,
      trigger: channels.triggerFinancialReport,
    },
    consentsClient: {
      post: channels.updateChannelConsents,
    },
    consentsClientData: {
      get: channels.getChannelConsentsClientData,
      post: channels.updateChannelConsentsClientData,
    },
    consents: {
      patch: channels.updateConsents,
      delete: channels.deleteConsents,
    },
    number: {
      post: {
        addNumberChannel: channels.addNumberChannel,
        deleteNumber: channels.deleteNumber,
        revokeNumberDeletion: channels.revokeNumberDeletion,
      },
    },
    activations: {
      post: {
        submitChannel: channels.submitChannel,
        updateChannelStatus: channels.updateStatusChannel,
        updatePhoneName: channels.updatePhoneName,
        updateDeclinedPhoneName: channels.updateDeclinedPhoneName,
        channelsWabaRegistrationReminder: channels.channelsWabaRegistration,
        setAvailability: channels.setChannelAvailability,
        setVerified: channels.setChannelVerified,
        updateBillingFinish: channels.updateBillingFinish,
        updateBillingStart: channels.updateBillingStart,
        grantPermissions: channels.grantPermissions,
        enableLocalStorage: channels.enableLocalStorage,
      },
      get: {
        getPermissionsList: channels.getPermissionsList,
      },
    },
    migration: {
      submitMigration: channels.submitMigration,
      requestVerification: channels.requestVerification,
      verifyMigration: channels.verifyMigration,
      migrateChannel: channels.migrateChannel,
    },
    inbox: {
      changeInboxSettings: channels.changeInboxSettings,
    },
  },
  partnerChannelsV1: {
    get: {
      list: partnerChannels.getPartnerChannelsV1,
    },
  },
  partnerChannels: {
    get: {
      list: partnerChannels.getPartnerChannels,
      single: partnerChannels.getPartnerChannel,
    },
    post: {
      fromPipe: partnerChannels.addPartnerChannelFromPipe,
      fromSubmit: partnerChannels.addPartnerChannelFromSubmitWaba,
      fromMigrationForm: partnerChannels.addPartnerChannelFromMigrateWaba,
    },
  },
  comments: {
    get: {
      list: comments.getComments,
      single: comments.getComment,
    },
    post: comments.addComment,
  },
  jira: {
    post: {
      createTicket: jiraTicket.jiraTicketCreate,
      addComment: jiraTicket.addComment,
      uploadFileTicket: jiraTicket.uploadFileTicket,
      attachUploadFileTicket: jiraTicket.attachUploadFileTicket,
    },
    billing: {
      post: jiraTicket.jiraTicketUpdateBilling,
    },
    get: {
      getTickets: jiraTicket.getTickets,
      getTicket: jiraTicket.getTicket,
      getAttachment: jiraTicket.getAttachment,
    },
  },
  accounting: {
    triggerInvoicesCreation: accounting.triggerInvoicesCreation,
    triggerTemplateInvoicesCreation: accounting.triggerTemplateInvoicesCreation,
    triggerTemplateReportCreation: accounting.triggerTemplateReportCreation,
    triggerCbpReportCreation: accounting.triggerCbpReportCreation,
    triggerCbpReportDailyCreation: accounting.triggerCbpReportDailyCreation,
    triggerCbpInvoicesCreation: accounting.triggerCbpInvoicesCreation,
    stripe: {
      createPortal: accounting.stripeCreatePortal,
    },
    get: {
      autoRecharge: accounting.getAutoRecharge,
      funds: accounting.getClientBalance,
      partnerClientsFunds: accounting.getPartnerClientsBalance,
      channelBalance: accounting.getChannelBalance,
      channelBalanceList: accounting.getChannelBalanceList,
      channelAutoRecharge: accounting.getChannelAutoRecharge,
      channelAutoRechargeList: accounting.getChannelAutoRechargeList,
      channelsFilteredBalance: accounting.getFilteredChannelsBalance,
    },
    post: {
      addFunds: accounting.addFunds,
      addFundsFinish: accounting.addFundsFinish,
      calculateCost: accounting.calculateCost,
      autoRecharge: accounting.setAutoRecharge,
      addChannelFunds: accounting.addChannelFunds,
      requestRefund: accounting.requestRefund,
      channelAutoRecharge: accounting.setChannelAutoRecharge,
    },
  },
  publicRequests: {
    outageEvent: publicRequests.outageEvent,
    verifyCaptcha: publicRequests.verifyCaptcha,
    triggerEmailVerification: publicRequests.triggerEmailVerification,
    verifyEmail: publicRequests.verifyEmail,
  },
  statusPage: {
    getOverallStatus: statusPage.getOverallStatus,
    addSubscriberMainStatusPage: statusPage.addSubscriberMainStatusPage,
  },
  notifications: {
    get: {
      list: notifications.getNotifications,
      getAllNotifications: notifications.getAllNotifications,
    },
    post: {
      markNotificationAsRead: notifications.markNotificationAsRead,
    },
  },
  insights: {
    get: {
      insightsStats: insights.getInsightsStats,
      sources: insights.getListOfSources,
      insightAllowed: insights.getInsightAllowed,
      actions: insights.getActions,
      actionsSourceDetails: insights.getActionsSourceDetails,
      externalEvents: insights.getExternalEvents,
      externalEventsSourceDetails: insights.getExternalEventsSourceDetails,
      fbAccounts: insights.getFBAccounts,
      apiKey: insights.getApiKey,
      downloadContactsLink: insights.downloadContactsLink,
    },
    patch: {
      setSourceName: insights.setSourceName,
    },
    post: {
      downloadContacts: insights.exportContacts,
      exportContactsBackground: insights.exportContactsBackground,
      connectFbAccount: insights.createFbAccount,
      refreshADs: insights.refreshADs,
      results: insights.results,
    },
  },
  integrations: {
    get: {
      getShopifyClientInfo: integrations.getShopifyClientInfo,
    },
    post: {
      attachShopify: integrations.attachShopify,
      usageCharge: integrations.usageCharge,
    },
  },
  audiences: {
    get: {
      getCustomAudiences: audiences.getCustomAudiences,
      getAudiencesGroupedByAdAccounts:
        audiences.getAudiencesGroupedByAdAccounts,
    },
    post: {
      createAudiencesFromContacts: audiences.createFromContacts,
      generateCandidatesFromContacts: audiences.generateCandidatesFromContacts,
    },
  },
  tour: {
    get: {
      getTourByTopic: tour.getTourByTopic,
    },
    post: {
      saveTour: tour.saveTour,
    },
  },
  tos: {
    get: {
      getAcceptance: tos.getAcceptance,
      getLatestTosVersion: tos.getLatestTosVersion,
      getTosDocByRevision: tos.getTosDocByRevision,
    },
    post: {
      acceptTos: tos.acceptTos,
    },
  },
};

export default admin;
