// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.SET_partnerName]: "Integration partner",
  [keys.SET_partnerPermissions]: "Partner permissions",
  [keys.SET_selectTitle]:
    "Select the numbers, for which you want to give permission to the partner to access the WhatsApp Business API.",
  [keys.SET_saveButton]: "Save changes",
  [keys.SET_successGrantPermissions]:
    "You have successfully updated permissions",
  [keys.SET_Inboxes]: "Inboxes",
  [keys.SET_selectNumberInbox]:
    "Select the numbers that you want to connect to your get.chat inbox",
  [keys.SET_requestPartnerChange]: "Request partner change",
  [keys.SET_requestApproved]:
    "Partner change request approved - Migration on {{date}}",
  [keys.SET_requestPending]: "Partner change request pending",
  [keys.SET_selectNewPartner]: "Select new partner",
  [keys.SET_confirmTerms]: "Confirm terms",
  [keys.SET_submitPartnerChangeRequest]: "Submit partner change request",
  [keys.SET_addPartnerIdMessage]:
    "Please provide the Partner ID of the new integration partner, to which you want to migrate to. Please make sure you’re in touch with your new partner before submitting this request. They will also be able to provide you with this ID.",
  [keys.SET_enterPartnerId]: "Enter partner ID",
  [keys.SET_notValidPartnerId]: "This is not a valid partner ID",
  [keys.SET_partnerIdFound]: "Partner ID found",
  [keys.SET_newPartnerText]: "Your new partner will be ",
  [keys.SET_currentPartnerText]: "This is your current partner",
  [keys.SET_currencyMismatchErrorText]:
    "The new partner’s supported billing currency does not match with the currency of your WhatsApp Business Account(s). Please use the form below to submit a message to our support for currency conversion.",
  [keys.SET_messageSupportLabel]: "Your message to our support",
  [keys.SET_messageSupportPlaceholder]: "How can we help you?",
  [keys.SET_billingPlanDesc1]:
    "Unlimited incoming and outgoing WhatsApp session messages",
  [keys.SET_billingPlanDesc2]:
    "Outgoing template messages according to WhatsApp price list. No markup. ¹",
  [keys.SET_billingPlanDesc3]: ">98% Service Level",
  [keys.SET_billingPlanDesc4]:
    "WhatsApp Official Business Account (green badge) upon approval",
  [keys.SET_partnerChangeConditionText]:
    "The new partner requires you to pay all fees directly to 360dialog. Please review the terms below and select your plan before confirming.",
  [keys.SET_messageToNewPartner]: "Optional message to your new partner",
  [keys.SET_messageToNewPartnerPlaceholder]:
    "Add any relevant information for your new partner, such as user ID, email, support ticket ID, etc.",
  [keys.SET_partnerChangeRequestSubmitted]:
    "Your partner change request was successfully submitted. You will be notified as soon as the migration is complete.",
  [keys.SET_cancelling]: "Cancelling Partner Change Request?",
  [keys.SET_rejectionInputLabel]:
    "Please indicate the reason for the rejection",
  [keys.SET_rejectionInputPlaceholder]: "Enter reason for rejection",
  [keys.SET_rejectionInputError]:
    "Please provide an answer of minimum {{min}} characters",
  [keys.SET_rejectClient]: "Reject client",
  [keys.SET_acceptClient]: "Accept client",
  [keys.SET_allExternalWabaMustHaveSameCurrency]:
    "All client’s external WhatsApp business accounts need to have the same currency in Meta",
  [keys.SET_paymentPlanCurrencyHasToMatch]:
    "Target partner and current partner payment plan currency has to match.",
  [keys.SET_partnerChangeRequestTargetPartnerPaymentPlanRequired]:
    "The target partner is missing a payment plan. Please contact support.",
  [keys.SET_partnerChangeRequestTargetPartnerPricingPlanRequired]:
    "The target partner is missing a valid pricing plan. Please contact support.",
  [keys.SET_partnerChangeRequestClientPaymentMethodRequired]:
    "The new partner requires you to have a valid payment method. Please add a payment method.",
  [keys.SET_wabaAndPaymentPlanCurrencyMustMatch]:
    "Messaging currency, Whatsapp business account currency and payment plan currency must match.",
  [keys.SET_paymentPlanHasToMatch]: "Payment Plan has to match.",
  [keys.SET_addPaymentMethod]: "Add payment method",
  [keys.SET_migrationNote]:
    "Please note all numbers related to this account will be moved to the new partner.",
  [keys.SET_apiKeyRecommendation]: "We recommend retrieving a new API KEY",
  [keys.SET_languageSelection]: "Language selection",
  [keys.SET_askForRefund]:
    "Remember to ask for a refund of your unused balance.",
  [keys.SET_disabledPartnerChangeTooltip]:
    "Partner change requests are temporarily not available. This feature will return on 15.03.2023.",
  [keys.SET_addCompanyInfo]: "Add company info",
  [keys.SET_selectPlan]: "Select plan",
  [keys.SET_triggerRefundMessage]:
    "Please trigger a refund for all your numbers before submitting the partner change request. You can achieve this on the funds manager section of the Insights and Billing page.",
  [keys.SET_errorPartnerChange]: "You can't change to this partner",
  [keys.SET_errorPartnerChangeInfo]:
    "To change to the destination partner you selected you need to comply with:",
  [keys.SET_errorPartnerChangeHelp]:
    "If you need help please <LINK>Contact Support<LINK>.",
  [keys.SET_PCRNotEnabled]:
    "Changing partner is currently not available. We are working to bring it back soon.",
  [keys.SET_standardBillNotice]:
    "All your numbers will continue to be billed according to our standard pricing. You can up- and downgrade your subscription plan for each individual number at any time.",
  [keys.SET_premiumBillNotice]:
    "All numbers that were previously upgraded to a premium plan will continue to be charged with the premium fee.",
  [keys.SET_updateDetails]:
    "Your company's general information and billing details",
  [keys.SET_generalInfo]: "General",
  [keys.SET_nameCompany]: "General details of your company",
  [keys.SET_locationCompany]: "Billing details of your company",
  [keys.SET_editDetails]: "Edit details",
  [keys.SET_saveDetails]: "Save details",
  [keys.SET_checkYourInvoices]: "Your company's issued invoices",
  [keys.SET_invoiceId]: "Invoice Id",
  [keys.SET_invoiceIssueAt]: "Issue at",
  [keys.SET_inviteMember]: "Invite a member",
  [keys.SET_inviteMemberProject]:
    "Invite a member of your team to join the project",
  [keys.SET_member]: "Member",
  [keys.SET_companyMembers]: "Your company's team members",
  [keys.SET_role]: "Role",
  [keys.SET_informationPartner]: "Information about your partner",
  [keys.SET_changePartner]: "Change partner",
  [keys.SET_currentPartner]: "Current partner",
  [keys.SET_currentPartnerInfo]: "Your current's partner information",
  [keys.SET_selectedPaymentMethod]: "Your company's selected payment method",
  [keys.SET_changePaymentMethod]: "Change payment method",
  [keys.SET_cardDetails]: "Card details of your company",
  [keys.SET_cardHolder]: "Holder",
  [keys.SET_cardExpiryDate]: "Expiry date",
  [keys.SET_cardEndsWith]: "Ends with",
  [keys.SET_card]: "Card",
  [keys.SET_changeEmail]: "Change email address",
  [keys.SET_verifyEmailText]: "Verify new email",
  [keys.SET_emailChangeSuccessful]: "Email address changed successfully",
  [keys.SET_passcodeText]: "Enter the passcode we sent to {{email}}",
  [keys.SET_emailToChangeToText]:
    "Enter the email address you want to change to",
  [keys.SET_changeName]: "Edit name",
  [keys.SET_nameToChangeToText]: "Enter the name you want to change to",
  [keys.SET_nameChangeSuccessful]: "Name changed successfully",
  [keys.SET_messagingAndPaymentPlanCurrencyMustMatchForINR]:
    "You can't proceed with the partner change. The currency your WABAs are setup with do not match the destination partner plan's selected currency.",
  [keys.SET_general]: "General",
  [keys.SET_payment]: "Payment",
  [keys.SET_support]: "Support",
  [keys.SET_partner]: "Partner",
  [keys.SET_adAccounts]: "Ad Accounts",
  [keys.SET_invoices]: "Invoices",
  [keys.SET_documentation]: "Documentation",
  [keys.SET_roadmap]: "Roadmap",
  [keys.SET_logout]: "Log out",
  [keys.SET_companyAndBilling]: "Company and billing details",
  [keys.SET_teamManagement]: "Team management",
  [keys.SET_syncedAdAccounts]: "Synced Ad Accounts",
  [keys.SET_getAssistance]: "Get assistance",
  [keys.SET_selectedPayment]: "Selected payment method",
  [keys.SET_issuedInvoices]: "Issued invoices",
  [keys.SET_aboutPartner]: "About your partner",
  [keys.SET_companyGeneral]:
    "Your company's general information and billing details",
  [keys.SET_connectAdAccount]: "Connect ad account",
  [keys.SET_adAccountsDescription]:
    "Connect your Facebook account to unlock powerful features: track ad campaigns, link them to conversations, enhance custom audience creation, and seamlessly integrate with WhatsApp conversations.",
  [keys.SET_adAccountsEmptyTitle]: "Connect Ad account. We’ll measure.",
  [keys.SET_adAccountsEmptyDescription]:
    "Connect your Meta Ad account in order to measure the performance of your conversation sources.",
  [keys.SET_accountName]: "Account Name",
  [keys.SET_accountID]: "Account ID",
  [keys.SET_refreshData]: "Refresh AD Account Data",
  [keys.SET_successfulRefreshedDataMsg]: "AD account data updated successfully",
  [keys.SET_refreshingDataInFlightMsg]:
    "Refreshing AD account data, please wait a moment",
  [keys.SET_noInvoices]: "No invoices",
  [keys.SET_noInvoicesDescription]:
    "There are no previous data for invoices registered yet",
  [keys.SET_name]: "name",
  [keys.SET_email]: "email",
  [keys.SET_paid]: "Paid",
  [keys.SET_unpaid]: "Unpaid",
  [keys.SET_error]: "Error",
  [keys.SET_pending]: "Pending",
  [keys.SET_processing]: "Processing",
  [keys.SET_refunded]: "Refunded",
  [keys.SET_refundTriggered]: "Refund triggered",
  [keys.SET_partiallyRefunded]: "Partially refunded",
  [keys.SET_partialRefundTriggered]: "Partial refund triggered",
  [keys.SET_triggered]: "Triggered",
  [keys.SET_unknownStatus]: "N/A",
  [keys.SET_status]: "Status",
  [keys.SET_companyTeamMembers]: "Your company’s team members",
  [keys.SET_confirmPaymentMethod]: "Confirm payment method",
  [keys.SET_confirmPaymentMethodDesc]:
    "Please confirm your payment method to continue the partner change process.",
  [keys.SET_pendingApprovalStatus]: "Partner change request pending approval",
  [keys.SET_cancelPCRTitle]: "Cancel Partner Change Request",
  [keys.SET_cancelPCRDesc]:
    "Do you want to cancel your Partner Change Request?",
  [keys.SET_submitPartnerChangeRequestMessage]:
    "Your partner change request was sent and is being processed",
  [keys.SET_PCRfailed]: "Partner Change Request Failed",
  [keys.SET_funds]: "Funds",
  [keys.SET_aboutFunds]: "Charges for the WABA",
  [keys.SET_activity]: "Activity",
  [keys.SET_aboutActivity]: "Conversations and expenses",
};

export default translate;
