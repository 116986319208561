// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.SET_partnerName]: "Integração de partner",
  [keys.SET_partnerPermissions]: "Permissões de partner",
  [keys.SET_selectTitle]:
    "Selecione os números para os quais você deseja dar permissão ao partner para acessar a WhatsApp Business API.",
  [keys.SET_saveButton]: "Salvar alterações",
  [keys.SET_successGrantPermissions]: "Permissões atualizadas com sucesso",
  [keys.SET_Inboxes]: "Inboxes",
  [keys.SET_selectNumberInbox]:
    "Selecione os números que você deseja conectar à sua caixa de entrada get.chat",
  [keys.SET_requestPartnerChange]: "Solicitar mudança de partner",
  [keys.SET_requestApproved]:
    "Solicitação de alteração de partner aprovada - Migração em {{date}}",
  [keys.SET_requestPending]: "Solicitação de alteração de partner pendente",
  [keys.SET_selectNewPartner]: "Selecione novo partner",
  [keys.SET_confirmTerms]: "Confirmar termos",
  [keys.SET_submitPartnerChangeRequest]:
    "Enviar solicitação de alteração de parceiro",
  [keys.SET_addPartnerIdMessage]:
    "Forneça o ID do novo partner de integração para o qual você deseja migrar. Certifique-se de entrar em contato com seu novo partner antes de enviar esta solicitação. Eles também poderão fornecer esse ID.",
  [keys.SET_enterPartnerId]: "Insira o ID do partner",
  [keys.SET_notValidPartnerId]: "Esse não é um partner ID válido",
  [keys.SET_partnerIdFound]: "Partner ID encontrado",
  [keys.SET_newPartnerText]: "Seu novo partner será",
  [keys.SET_currentPartnerText]: "Esse é o seu partner atual",
  [keys.SET_currencyMismatchErrorText]:
    "A moeda de cobrança compatível com o novo parceiro não corresponde à moeda das suas contas do WhatsApp Business. Use o formulário abaixo para enviar uma mensagem ao nosso suporte para conversão de moeda.",
  [keys.SET_messageSupportLabel]: "Sua mensagem para nosso suporte",
  [keys.SET_messageSupportPlaceholder]: "Como podemos te ajudar?",
  [keys.SET_billingPlanDesc1]:
    "Mensagens de sessão de WhatsApp de entrada e saída ilimitadas",
  [keys.SET_billingPlanDesc2]:
    "Mensagens de modelo de saída de acordo com a lista de preços do WhatsApp. Sem marcação. ¹",
  [keys.SET_billingPlanDesc3]: ">98% Nível de Serviço",
  [keys.SET_billingPlanDesc4]:
    "Conta comercial oficial do WhatsApp (selo verde) após aprovação",
  [keys.SET_partnerChangeConditionText]:
    "O novo partner exige que você pague todas as taxas diretamente para a 360dialog. Por favor, revise os termos abaixo e selecione seu plano antes de confirmar.",
  [keys.SET_messageToNewPartner]: "Mensagem opcional para seu novo partner",
  [keys.SET_messageToNewPartnerPlaceholder]:
    "Adicione qualquer informação relevante para seu novo partner, como ID de usuário, e-mail, ID de ticket de suporte, etc.",
  [keys.SET_partnerChangeRequestSubmitted]:
    "Sua solicitação de alteração de partner foi enviada com sucesso. Você será notificado assim que a migração for concluída.",
  [keys.SET_cancelling]: "Cancelar solicitação de mudança de partner?",
  [keys.SET_rejectionInputLabel]: "Por favor indicar a razão da rejeição",
  [keys.SET_rejectionInputPlaceholder]: "Inserir razão da rejeição",
  [keys.SET_rejectionInputError]:
    "Por favor enviar a resposta com o minimo de {{min}} caracteres",
  [keys.SET_rejectClient]: "Rejeitar cliente",
  [keys.SET_acceptClient]: "Aceitar cliente",
  [keys.SET_allExternalWabaMustHaveSameCurrency]:
    "Todas as contas WhatsApp business precisam ter a mesma moeda no Meta",
  [keys.SET_paymentPlanCurrencyHasToMatch]:
    "O novo partner e a moeda do plano de pagamento do partner atual precisam ser iguais.",
  [keys.SET_partnerChangeRequestTargetPartnerPaymentPlanRequired]:
    "O novo partner precisa ajustar o plano de pagamento. Por favor contate o suporte.",
  [keys.SET_partnerChangeRequestTargetPartnerPricingPlanRequired]:
    "O novo partner precisa ajustar um plano de pagamento válido. Por favor contate o suporte.",
  [keys.SET_partnerChangeRequestClientPaymentMethodRequired]:
    "O novo partner exige que você tenha um método de pagamento válido. Por favor, adicione um método de pagamento.",
  [keys.SET_wabaAndPaymentPlanCurrencyMustMatch]:
    "A moeda das mensagens, da conta de WhatsApp business e a moeda do plano de pagamento devem corresponder.",
  [keys.SET_paymentPlanHasToMatch]: "O plano de pagamento precisa ser igual.",
  [keys.SET_addPaymentMethod]: "Adicionar método de pagamento.",
  [keys.SET_migrationNote]:
    "Por gentileza notar que todos os numeros relacionados a essa conta serão movidos ao novo partner.",
  [keys.SET_apiKeyRecommendation]: "Recomendados solicitar uma nova API KEY.",
  [keys.SET_languageSelection]: "Seleção do idioma",
  [keys.SET_askForRefund]:
    "Lembre-se de pedir um reembolso do seu saldo não utilizado.",
  [keys.SET_disabledPartnerChangeTooltip]:
    "Os pedidos de mudança de partner estão temporariamente indisponíveis. Este recurso retornará em 15.03.2023.",
  [keys.SET_addCompanyInfo]: "Adicionar informação da empresa",
  [keys.SET_selectPlan]: "Selecionar plano",
  [keys.SET_triggerRefundMessage]:
    "Você deve acionar um reembolso para todos os seus números antes de enviar a solicitação de alteração de parceiro. Você pode fazer isso na seção do gerente de fundos da página Insights and Billing.",
  //  [keys.SET_errorPartnerChange]: "translation_missing",
  //  [keys.SET_errorPartnerChangeInfo]: "translation_missing",
  //  [keys.SET_errorPartnerChangeHelp]: "translation_missing",
  //  [keys.SET_PCRNotEnabled]: "translation_missing",
  //  [keys.SET_standardBillNotice]: "translation_missing",
  //  [keys.SET_premiumBillNotice]: "translation_missing",
  //  [keys.SET_updateDetails]: "translation_missing",
  //  [keys.SET_generalInfo]: "translation_missing",
  [keys.SET_nameCompany]: "Detalhes gerais da sua empresa",
  [keys.SET_locationCompany]: "Detalhes de faturamento da sua empresa",
  [keys.SET_editDetails]: "Editar detalhes",
  //  [keys.SET_saveDetails]: "translation_missing",
  //  [keys.SET_checkYourInvoices]: "translation_missing",
  //  [keys.SET_invoiceId]: "translation_missing",
  //  [keys.SET_invoiceIssueAt]: "translation_missing",
  [keys.SET_inviteMember]: "Convide um membro",
  //  [keys.SET_inviteMemberProject]: "translation_missing",
  //  [keys.SET_member]: "translation_missing",
  [keys.SET_companyMembers]: "Os membros da sua empresa",
  //  [keys.SET_role]: "translation_missing",
  [keys.SET_informationPartner]: "Informação sobre seu parceiro",
  [keys.SET_changePartner]: "Mudar de parceiro",
  //  [keys.SET_currentPartner]: "translation_missing",
  [keys.SET_currentPartnerInfo]: "Informação sobre seu parceiro atual",
  //  [keys.SET_selectedPaymentMethod]: "translation_missing",
  //  [keys.SET_changePaymentMethod]: "translation_missing",
  //  [keys.SET_cardDetails]: "translation_missing",
  //  [keys.SET_cardHolder]: "translation_missing",
  //  [keys.SET_cardExpiryDate]: "translation_missing",
  //  [keys.SET_cardEndsWith]: "translation_missing",
  //  [keys.SET_card]: "translation_missing",
  //  [keys.SET_changeEmail]: "translation_missing",
  //  [keys.SET_verifyEmailText]: "translation_missing",
  //  [keys.SET_emailChangeSuccessful]: "translation_missing",
  //  [keys.SET_passcodeText]: "translation_missing",
  //  [keys.SET_emailToChangeToText]: "translation_missing",
  //  [keys.SET_changeName]: "translation_missing",
  //  [keys.SET_nameToChangeToText]: "translation_missing",
  //  [keys.SET_nameChangeSuccessful]: "translation_missing",
  //  [keys.SET_messagingAndPaymentPlanCurrencyMustMatchForINR]: "translation_missing",
  [keys.SET_general]: "Geral",
  [keys.SET_payment]: "Pagamento",
  [keys.SET_support]: "Suporte",
  [keys.SET_partner]: "Parceiro",
  [keys.SET_adAccounts]: "Contas de Ad",
  [keys.SET_invoices]: "Fatura",
  [keys.SET_documentation]: "Documentação",
  [keys.SET_roadmap]: "Roadmap",
  [keys.SET_logout]: "Desconectar",
  [keys.SET_companyAndBilling]: "Detalhes da empresa e cobrança",
  [keys.SET_teamManagement]: "Gerenciamento do time",
  [keys.SET_syncedAdAccounts]: "Contas de ad conectadas",
  [keys.SET_getAssistance]: "Solicite assistência",
  [keys.SET_selectedPayment]: "Método de pagamento solicitado",
  [keys.SET_issuedInvoices]: "Faturas emitidas",
  [keys.SET_aboutPartner]: "Sobre o seu parceiro",
  [keys.SET_companyGeneral]:
    "Os detalhes gerais e informações de faturamento da sua empresa",
  [keys.SET_connectAdAccount]: "Conecte sua conta de ad",
  [keys.SET_adAccountsDescription]:
    "Conecte sua conta do Facebook para desbloquear recursos poderosos: rastreie campanhas de anúncios, vincule-as a conversas, aprimore a criação de públicos personalizados e integre-se de forma transparente com conversas do WhatsApp",
  //  [keys.SET_adAccountsEmptyTitle]: "translation_missing",
  //  [keys.SET_adAccountsEmptyDescription]: "translation_missing",
  [keys.SET_accountName]: "Nome da Conta",
  [keys.SET_accountID]: "ID da Conta",
  //  [keys.SET_refreshData]: "translation_missing",
  //  [keys.SET_successfulRefreshedDataMsg]: "translation_missing",
  //  [keys.SET_refreshingDataInFlightMsg]: "translation_missing",
  // [keys.SET_noInvoices]: "translation_missing",
  // [keys.SET_noInvoicesDescription]: "translation_missing",
  // [keys.SET_name]: "translation_missing",
  // [keys.SET_email]: "translation_missing",
  // SET_paid: "translation_missing",
  // SET_unpaid: "translation_missing",
  // SET_error: "translation_missing",
  // SET_pending: "translation_missing",
  // SET_processing: "translation_missing",
  // SET_refunded: "translation_missing",
  // SET_refundTriggered: "translation_missing",
  // SET_partiallyRefunded: "translation_missing",
  // SET_partialRefundTriggered: "translation_missing",
  // SET_triggered: "translation_missing",
  // SET_unknownStatus: "translation_missing",
  // SET_status: "translation_missing",
  [keys.SET_companyTeamMembers]: "Os membros da sua empresa",
  [keys.SET_confirmPaymentMethod]: "Confirmar o método de pagamento",
  [keys.SET_confirmPaymentMethodDesc]:
    "Confirme seu método de pagamento para continuar o processo de mudança de parceiro.",
  [keys.SET_pendingApprovalStatus]:
    "Solicitação de alteração de parceiro pendente de aprovação",
  [keys.SET_cancelPCRTitle]: "Cancelar solicitação de alteração de parceiro",
  [keys.SET_cancelPCRDesc]:
    "Deseja cancelar sua Solicitação de alteração de parceiro?",
  [keys.SET_submitPartnerChangeRequestMessage]:
    "Sua solicitação de mudança de parceiro foi enviada e está sendo processada",
  [keys.SET_PCRfailed]: "Falha na solicitação de alteração de parceiro",
  [keys.SET_funds]: "Fundos",
  [keys.SET_aboutFunds]: "Taxas para a WABA",
  [keys.SET_activity]: "Atividade",
  [keys.SET_aboutActivity]: "Conversas e despesas",
};

export default translate;
