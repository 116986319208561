// File generated automatically. Do not edit manually.
// import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.TOUR_eventFunnelTitle]: "translation_missing",
  //  [keys.TOUR_eventFunnelDesc]: "translation_missing",
  //  [keys.TOUR_addStepsTitle]: "translation_missing",
  //  [keys.TOUR_addStepsDesc]: "translation_missing",
  //  [keys.TOUR_highlightCardsTitle]: "translation_missing",
  //  [keys.TOUR_highlightCardsDesc]: "translation_missing",
  //  [keys.TOUR_understandSourcesTitle]: "translation_missing",
  //  [keys.TOUR_understandSourcesDescTitle]: "translation_missing",
  //  [keys.TOUR_understandSourcesDescOne]: "translation_missing",
  //  [keys.TOUR_understandSourcesDescTwo]: "translation_missing",
  //  [keys.TOUR_understandSourcesDescThree]: "translation_missing",
  //  [keys.TOUR_understandSourcesDescFour]: "translation_missing",
  //  [keys.TOUR_dateFiltersTitle]: "translation_missing",
  //  [keys.TOUR_dateFiltersDesc]: "translation_missing",
};

export default translate;
