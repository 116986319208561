// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.SUP_tickets]: "Tickets de soporte",
  [keys.SUP_id]: "ID",
  [keys.SUP_title]: "Título",
  [keys.SUP_number]: "Número",
  [keys.SUP_status]: "Estado",
  [keys.SUP_addComment]: "Agregar comentario",
  [keys.SUP_newTicket]: "Crear nuevo ticket",
  [keys.SUP_contactSupport]: "Póngase en contacto con el equipo de soporte",
  [keys.SUP_channelSupportHelptext]:
    "Si tiene problemas con este número, puede ponerse en contacto con nuestro soporte.",
  [keys.SUP_topic]: "Tema",
  [keys.SUP_selectTopicError]: "Seleccione el tema de su pregunta",
  [keys.SUP_selectWabaError]:
    "Por favor, seleccione la cuenta relacionada con su pregunta.",
  [keys.SUP_selectNumber]: "Seleccione el número de teléfono",
  [keys.SUP_selectNumberError]: "Seleccione el número en cuestión",
  [keys.SUP_selectWabaFirst]:
    "Seleccione primero la cuenta de WhatsApp Business",
  [keys.SUP_messagePlaceholder]: "¿Cómo podemos ayudarle?",
  [keys.SUP_other]: "Otro",
  [keys.SUP_numberNotAvailable]: "Número no disponible",
  [keys.SUP_send]: "Enviar mensaje",
  [keys.SUP_back]: "Vuelve a la lista",
  [keys.SUP_emptyStateTitle]: "Aún no ha creado ningún ticket.",
  [keys.SUP_emptyStateHelptext]:
    "Cree un ticket con nuestro equipo de soporte en caso de que tenga alguna pregunta o problema. Una vez que haya creadi un ticket, podrá ver las respuestas de nuestro equipo, así como el estado actual de su solicitud.",
  [keys.SUP_success]:
    "Su mensaje fue enviado. Nuestro soporte se pondrá en contacto con usted.",
  [keys.SUP_successComment]:
    "Su comentario fue enviado. Nuestro soporte se pondrá en contacto con usted.",
  [keys.SUP_commentOn]: "Añadir comentario al ticket",
  [keys.SUP_yourTickets]: "Sus tickets",
  [keys.SUP_docsLink]: "Documentación del 360dialog Client Hub",
  [keys.SUP_docsLinkSubtitle]:
    "Lea más sobre las prestaciones y cómo utilizr el 360dialog Client Hub",
  [keys.SUP_shortcutsLink]: "Atajos de teclado",
  [keys.SUP_shortcutsLinkSubtitle]:
    "Navegar por el 360dialog Client Hub de forma más eficiente",
  [keys.SUP_apiStatusLink]: "Estado de la WhatsApp Business API",
  [keys.SUP_apiStatusLinkSubtitle]:
    "Actualizaciones oficiales sobre el estado de la API proporcionadas por Meta",
  [keys.SUP_supportLink]: "Centro de soporte",
  [keys.SUP_supportLinkSubtitle]:
    "Consulte sus tickets abiertos y cree nuevos tickets para pedir ayuda o mandar sugerencias",
  [keys.SUP_openJiraLink]: "Abrir ticket en Jira",
  [keys.SUP_busVerificationIssue]: "Problema de verificación de la empresa",
  [keys.SUP_newSubmission]: "Nueva solicitud",
  [keys.SUP_unableToSubmit]: "Número no admitido",
  [keys.SUP_requestIVR]: "Solicitar números de IVR",
  [keys.SUP_otherQuestion]: "Otra pregunta",
  [keys.SUP_messagingNotWorking]: "La mensajería no funciona",
  [keys.SUP_internalServerErr]: "Error interno del servidor",
  [keys.SUP_sandboxSupport]: "Soporte de Sandbox",
  [keys.SUP_hubAccess]: "Acceso al Hub",
  [keys.SUP_commercePolicyRejection]:
    "Apelar denegación de la política de comercio",
  [keys.SUP_click2WhatsApp]: "Permitir anuncios de Click2WhatsApp",
  [keys.SUP_numberPorting]: "Portabilidad de números",
  [keys.SUP_nameChange]: "Cambio de Display Name",
  [keys.SUP_nameRejected]: "Display Name rechazado",
  [keys.SUP_cancellation]: "Cancelación (eliminar número)",
  [keys.SUP_onPremise]: "Soporte on-premise",
  [keys.SUP_paymentMethod]: "Modo de pago",
  [keys.SUP_govAccount]: "Solicitud de cuenta gubernamental",
  [keys.SUP_outsideWorkHours]:
    "Ha enviado un nuevo ticket de soporte fuera de nuestro horario laboral. Nuestro equipo de soporte trabaja de Lunes a Viernes de 2 de la mañana hasta las 11 de la noche UTC +1. Contactamos  cuando posible.",
  [keys.SUP_openStatusPage]: "Abrir página de status",
  [keys.SUP_allSystemsNormal]: "Operación normal",
  [keys.SUP_expIssues]: "Problemas identificados",
  [keys.SUP_underMaintenance]: "En manutención",
  [keys.SUP_warningCreateTicket1]:
    "Atención! Tenemos problemas en el sistema. Para detalles, por favor, visite <LINK>https://status.360dialog.com/ | Status Page<LINK>. Por favor, evite enviar tickets sobre los problemas mencionados.",
  [keys.SUP_warningCreateTicket2]:
    "Se puede inscribir para updates sobre el incidente en <LINK>https://status.360dialog.com/ | Status Page<LINK>.",
  [keys.SUP_statusLinkSubtitle]:
    "Visualizar e inscribir para cualquier incidente actual o antiguo",
  [keys.SUP_messageLengthError]:
    "El mensaje debe tener como mínimo 140 caracteres",
  [keys.SUP_clickHereButtonLabel]: "Haga clic aquí",
  [keys.SUP_paymentMethods]: "Métodos de pago",
  [keys.SUP_attachFile]: "Adjuntar imágenes",
  [keys.SUP_includeAllTickets]: "Incluir tickets de mi equipo",
  [keys.SUP_reporter]: "Solicitante",
  [keys.SUP_optionsLoading]: "Cargando opciones...",
  [keys.SUP_roadmap]: "Roadmap y feature requests",
  [keys.SUP_roadmapSubtitle]:
    "Vea nuestro roadmap y envía o vota feature requests",
  [keys.SUP_inReview]: "En revisión",
  [keys.SUP_responseViaEmail]:
    "Tenga en cuenta que recibirá su respuesta por correo electrónico.",
  [keys.SUP_onboarding]: "Onboarding",
  [keys.SUP_onboardingDescription]:
    "Registros de números, adición de números, portabilidad de números, Embedded Signup, etc...",
  [keys.SUP_problemWithMessaging]: "Problemas con la API de mensajería ",
  [keys.SUP_messagingProblemsDescription]:
    "Mensajes que no llegan, mensajes de error en la API, retrasos en los mensajes, números desconectados, etc...",
  [keys.SUP_accManagement]: "Administración de la cuenta",
  [keys.SUP_accManagementDescription]:
    "Verificación de Meta business, términos de Meta business, política de comercio, nombres para mostrar, cuentas comerciales oficiales, plantillas, límites de mensajes, niveles de calidad, información, saldo, cambios de socios, etc...",
  [keys.SUP_offboarding]: "Desvinculación",
  [keys.SUP_offboardingDescription]:
    "Cancelación, finalización, desactivación de 2FA, migración a otro BSP, degradaciones, etc...",
  [keys.SUP_financeAndBilling]: "Finanzas y facturación",
  [keys.SUP_financeAndBillingDescription]:
    "Facturación, suscripciones, devoluciones, medios de pago, etc...",
  [keys.SUP_otherQuestion]: "Otra pregunta",
  [keys.SUP_otherQuestionDescription]:
    "Cualquier otra cosa que no esté listada en las categorías anteriores",
  //  [keys.SUP_visitStatusPage1]: "translation_missing",
  //  [keys.SUP_visitStatusPage2]: "translation_missing",
};

export default translate;
