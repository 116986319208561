// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.INS_understandWhatWorksBest]: "Entenda o que é mais eficaz",
  [keys.INS_trackedConversion]: "Conversão selecionada",
  [keys.INS_conversationsFrom]: "Conversas de",
  [keys.INS_whereLeads]: "onde levam",
  [keys.INS_editConversions]: "Edite a conversão",
  [keys.INS_edit]: "Edite",
  [keys.INS_allSources]: "Todas as origens",
  [keys.INS_incoming]: "Que entra",
  [keys.INS_outgoing]: "Que saí",
  [keys.INS_organic]: "Mensagens orgânicas",
  [keys.INS_sourceName]: "Titulo da origem",
  [keys.INS_sourceBreakdown]: "Detalhes das origens",
  [keys.INS_startedConversations]: "Conversas iniciadas",
  [keys.INS_conversionRate]: "Taxa de conversão",
  [keys.INS_convertedLeads]: "Leads convertidos",
  [keys.INS_invested]: "Investido",
  [keys.INS_revenue]: "Receita",
  [keys.INS_downloadLeads]: "Download os leads",
  [keys.INS_searchNumber]: "Procure por um número",
  [keys.INS_downloadAllLeads]: "Leads",
  [keys.INS_downloadAllLeadsDescription]:
    "Baixe todos os leads cadastrados no intervalo de tempo selecionado, sem nenhum filtro específico aplicado.",
  [keys.INS_leadsDescription]: "Leads que iniciaram uma conversa",
  [keys.INS_percentageLeads]:
    "Porcentagem de leads que dispararam um evento de conversão",
  [keys.INS_leadsConversion]:
    "Total de leads que dispararam um evento de conversão",
  [keys.INS_investedDescription]:
    "Quantidade investida para disparar eventos de conversão",
  [keys.INS_conversionCost]:
    "O custo associado a cada aquisição bem-sucedida de leads, com base no valor investido",
  [keys.INS_revenueDescription]:
    "Quantidade de renda gerada através desse evento de conversão",
  [keys.INS_returnDescription]:
    "O Retorno sobre o investimento em publicidade (ROAS) é a receita gerada por cada unidade de moeda investida",
  [keys.INS_impressionsTitle]: "Impressões",
  [keys.INS_impressionsDescription]:
    "Número de vezes que um anúncio foi carregado na página",
  [keys.INS_paidClicksTitle]: "Cliques Pagos",
  [keys.INS_paidClicksDescription]: "Quantidade de cliques em um anúncio CTWA",
  [keys.INS_roasDescription]: "ROAS não disponível.",
  [keys.INS_learnEnable]: " Aprenda mais sobre como habilitá-lo",
  [keys.INS_costPerConversion]: "Custo Por Conversão",
  [keys.INS_revenueNotAvailable]: "ROAS não disponível",
  [keys.INS_adsFrom]: "Anúncios de → Todos os conjuntos de Anúncio",
  [keys.INS_sourceInfo]: "Informação da origem",
  [keys.INS_detailedInfo]: "Informações detalhadas da origem selecionada",
  [keys.INS_typeLabel]: "Tipo",
  [keys.INS_typeInfo]: "Meta",
  [keys.INS_typeDescription]:
    "Origens do tipo Meta são anúncios de Clique para o WhatsApp",
  [keys.INS_incomingCustom]: "Origem de entrada",
  [keys.INS_incomingCustomDescription]:
    "Origens de entrada são origens onde o lead iniciou uma conversa. Podendo ser através de QR Codes, WA.me links, etc.",
  [keys.INS_giveThisSourceName]: "Dê um nome para a origem",
  [keys.INS_yesterday]: "Ontem",
  [keys.INS_enableIt]: "Habilite",
  [keys.INS_steps]: "step(s)",
  [keys.INS_createNewFunnel]: "Criar novo funil",
  [keys.INS_nameFunnel]: "Nomear funil",
  [keys.INS_replaceEvent]: "Substituir por outro evento",
  [keys.INS_selectEvent]: "Selecionar um evento",
  [keys.INS_removeEvent]: "Remover evento do funil",
  [keys.INS_mustNotExceed20]: "O nome não deve exceder 20 caracteres",
  [keys.INS_costPerAcquisition]: "Custo por Aquisição",
  [keys.INS_inThreadEvent]: "Evento in-thread",
  [keys.INS_offThreadEvent]: "Evento off-thread",
  [keys.INS_funnelUpdated]: "Funil atualizado",
  [keys.INS_sourceTypes]: "Todas as origens",
  [keys.INS_sources]: "Origens",
  [keys.INS_sourcesDescription]:
    "Tipos de fontes usadas para promover conversas",
  [keys.INS_messages]: "Mensagens",
  [keys.INS_folders]: "Pastas",
  [keys.INS_ads]: "Anúncios",
  [keys.INS_metaClickToWhatsApp]: "Campanhas CTWA",
  [keys.INS_metaCTWA]: "Meta CTWA",
  [keys.INS_incomingCampaigns]: "Mensagens recebidas",
  [keys.INS_outgoingTemplates]: "Mensagens enviadas",
  [keys.INS_open]: "Abrir",
  [keys.INS_custom]: "Custom",
  [keys.INS_lastWeek]: "Última semana",
  [keys.INS_last2Weeks]: "Últimas 2 semanas",
  [keys.INS_lastMonth]: "Último mês",
  [keys.INS_cancel]: "Cancelar",
  [keys.INS_apply]: "Aplicar",
  [keys.INS_bestRoas]: "Melhor",
  [keys.INS_bestRoasTooltip]:
    "Parece que este anúncio está tendo um desempenho consideravelmente melhor do que os outros deste mesmo conjunto de anúncios.",
  [keys.INS_bestRoasTooltipLink]: "Aprenda como melhorar o desempenho geral",
  [keys.INS_worstRoas]: "Pior",
  [keys.INS_worstRoasTooltip]:
    "Parece que este anúncio está tendo um desempenho consideravelmente pior do que os outros deste mesmo conjunto de anúncios.",
  [keys.INS_worstRoasTooltipLink]: "Aprenda como melhorar seu desempenho",
  // [keys.INS_orderingFilter]: "translation_missing",
  //  [keys.INS_selectEventToViewDetails]: "translation_missing",
  [keys.INS_details]: "Detalhes",
};

export default translate;
